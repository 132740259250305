import React from 'react'
import Menu from '../components/menu-component'
// import * as _ from 'lodash';

import Layout from '../components/layout'
import './index.css';

const IndexPage = (props) => {
  return (
    <Layout>
      <h1>Tim Williams</h1>
      <p>Web developer and software engineer from the San Francisco Bay Area.</p>
      <Menu/>
      <div>
          <div>
              <h3>Developer</h3>
              <p>Hi there! I'm Tim, a web developer and software engineer from the SF bay area. I've always had a passion for problem solving and technical challenges. I love programming I find it to be a beautiful convergence of both cognitive thinking and artistic creativity.</p>
          </div>
          <div>
              <h3>Designer</h3>
              <p>I love bringing peoples ideas to life with design and functionality. I'm a big fan of data visualizations made possible by libraries like <a href="http://d3js.org/">D3.js</a> I've love how they can offer insight into sets of data.</p>
          </div>
          <div>
              <h3>Get in touch with me!</h3>
              <p>Here on my site you'll find my resume, my writing and a showcase of my work. You can reach me via any of my social media links. This site is still in it's infancy so the content will be flowing in, check back often! </p>
          </div>
      </div>
    </Layout>
  )
}

export default IndexPage